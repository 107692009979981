import PropTypes from 'prop-types'
import React from 'react'

const Viewing = ({ displayedItemsNumber, pagination }) => {
  return (
    <div>
      {Spree.translation('spree.tcred.products.viewing_of_count',
        { count: displayedItemsNumber, total_count: pagination?.total_count})}
    </div>
  )
}

export default Viewing

Viewing.propTypes = {
  displayedItemsNumber: PropTypes.number,
  pagination: PropTypes.object,
}
