import React from 'react'
import PropTypes from 'prop-types'

function TCVolumePricesTable(props) {
  const { ranges } = props
  const t = Spree.translations

  return (
    <div className={'row'}>
      <div className={'col-md-6'}>
        <h5>{t.volume_pricing}</h5>
        <div className={'tableComponent'}>
          <table className={'tableComponent_table'}>
            <thead>
              <tr>
                <th>{t.volume_prices_quantity}</th>
                <th>{t.volume_prices_price}</th>
              </tr>
            </thead>
            <tbody>
              {ranges.map((item, index) => (
                <tr key={Math.random()}>
                  <td>{item.range[1] === '99999'? `${item.range[0]}+` : item.range.join(' - ')}</td>
                  <td>{item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

TCVolumePricesTable.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string.isRequired,
      range: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired
  ).isRequired,
}

export default TCVolumePricesTable

