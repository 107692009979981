import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

export default function TCStickyAddToCart(props) {
  const { children } = props

  const [scrolled,setScrolled]=React.useState(false)

  const [scrollStatus, setScrollStatus] = React.useState({
    scrollDirection: null,
    scrollPos: 0,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDocument)

    return () => window.removeEventListener('scroll', handleScrollDocument)
  }, [])

  function handleScrollDocument() {
    setScrollStatus((prev) => { // to get 'previous' value of state
      return {
        scrollDirection:
            document.body.getBoundingClientRect().top > prev.scrollPos
              ? 'up'
              : 'down',
        scrollPos: document.body.getBoundingClientRect().top,
      }
    })
  }

  const handleScroll=() => {
    const offset=window.scrollY
    const mainAddToCart = document.getElementById('mainAddToCart')
    let pos = mainAddToCart.offsetTop
    if(offset > pos ) {
      setScrolled(true)
    }
    else{
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })

  let x=['stickyBar']
  if(scrolled) {
    x.push('scrolled')
  }
  if(scrollStatus.scrollDirection === 'up' && scrolled) {
    x.push('combined')
  }

  return (
    <div className={x.join(' ')}>
      {children}
    </div>
  )
}

TCStickyAddToCart.propTypes = {
  children: PropTypes.any,
}
