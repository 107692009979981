import React from 'react'
import PropTypes from 'prop-types'
import { renderStarsPercentage } from './utils'

const TCReview = ({ avg_rating, product_url, reviews_count }) => {
  const t = Spree.translations

  const scrollToReviews = () => {
    const reviews = document.getElementById('reviews')
    if (reviews && window.location.pathname === product_url) {
      reviews.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      window.open(`${product_url}/#reviews`, '_self')
    }
  }

  return (
    <div className="productRightSide_rating">
      <div className="productRightSide_ratingStars">
        {renderStarsPercentage(parseFloat(avg_rating))}
      </div>
      <span className="productRightSide_ratingCount" onClick={scrollToReviews}>
        {reviews_count} {reviews_count === 1 ? t.review : t.reviews}
      </span>
    </div>
  )
}

TCReview.propTypes = {
  avg_rating: PropTypes.string.isRequired,
  product_url: PropTypes.string.isRequired,
  reviews_count: PropTypes.number.isRequired,
}

export default TCReview
