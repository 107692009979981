import React from 'react'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import 'react-multi-carousel/lib/styles.css'

const TCUpCycleCarousel = props => {
  const responsive = {
    desktop: {
      breakpoint: { max: 99999, min: 991 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    },
  }

  return (
    <Carousel
      className="upcycle-carousel"
      draggable={false}
      infinite={true}
      removeArrowOnDeviceType="desktop"
      responsive={responsive}
    >
      {props.products.map((item) =>
        <div className="item"
          key={btoa(item.link)}
          onClick={() => window.location.href=item.link}
        >
          <div className="upcycle-carousel__img">
            <img
              alt=""
              src={item.image}
            />
          </div>
          <div className="upcycle-carousel__title mt-3">
            <span>{item.name}</span>
          </div>
        </div>,
      )}
    </Carousel>
  )
}

TCUpCycleCarousel.propTypes = {
  products: PropTypes.array,
}

export default TCUpCycleCarousel
