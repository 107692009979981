import React from 'react'
import PropTypes from 'prop-types'
import { TcQuickView } from '@tc-packages/tc-common'

const TCQuickViewWrapper = ({ id, buttonStyles, config }) => (
  <TcQuickView buttonStyles={buttonStyles} config={config} id={id} />
)

TCQuickViewWrapper.propTypes = {
  buttonStyles: PropTypes.object,
  config: PropTypes.object.isRequired,
  id: PropTypes.number,
}

export default TCQuickViewWrapper
