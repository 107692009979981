import PropTypes from 'prop-types'
import React from 'react'

const ActiveFilters = ({
  activeFilters,
  setIsKeywordParamsRemoved,
  setIsPriceParamsRemoved,
  setIsTaxonParamsRemoved,
  setKeyword,
  setPriceTitle,
  setPriceValue,
  setTaxonTitle,
  setTaxonValue,
}) => {
  return (
    <div className="collectionFilter_filter-tags">
      <ul className="collectionFilter_filter-tags-list">
        {activeFilters.map((item) => {
          if (item.name !== '') {
            return (
              <li className="collectionFilter_filter-tags-item" key={Math.random()}>
                <span>{item.name}</span>
                <button
                  className="collectionFilter_filter-tags-remove"
                  onClick={() => {
                    if (item.type === 'taxon') {
                      setIsTaxonParamsRemoved(true)
                      setTaxonTitle('')
                      setTaxonValue('')
                    }
                    if (item.type === 'keyword') {
                      setIsKeywordParamsRemoved(true)
                      setKeyword('')
                    }
                    if (item.type === 'price') {
                      setIsPriceParamsRemoved(true)
                      setPriceTitle('')
                      setPriceValue('')
                    }
                  }}
                >
                  <div className="plusIcon">+</div>
                </button>
              </li>
            )
          } else {
            return null
          }
        })}
      </ul>

      {activeFilters.filter((item) => item.name !== '').length ? (
        <div
          className="collectionFilter_filter-tags-clear"
          onClick={() => {
            setIsKeywordParamsRemoved(true)
            setIsPriceParamsRemoved(true)
            setIsTaxonParamsRemoved(true)
            setKeyword('')
            setPriceTitle('')
            setPriceValue('')
            setTaxonTitle('')
            setTaxonValue('')
          }}
        >
          Clear Filters
        </div>
      ) : null}
    </div>
  )
}

export default ActiveFilters

ActiveFilters.propTypes = {
  activeFilters: PropTypes.array,
  setIsKeywordParamsRemoved: PropTypes.func,
  setIsPriceParamsRemoved: PropTypes.func,
  setIsTaxonParamsRemoved: PropTypes.func,
  setKeyword: PropTypes.func,
  setPriceTitle: PropTypes.func,
  setPriceValue: PropTypes.func,
  setTaxonTitle: PropTypes.func,
  setTaxonValue: PropTypes.func,
}
