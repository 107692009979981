import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'

const RadioSizeSelector = ({ itemName, itemList = [], updateValue, variant }) => {

  // Context API
  const filteredItemList = itemList.filter(item => !!item.name)

  // variables
  const display = filteredItemList.length > 0
  const selectedItem = variant

  const handleChange = (e) => {
    updateValue(Number(e.target.value))
  }

  return (
    display &&
    <div className={`${itemName} col-xl-12`}>
      <div className="bootstrapForm_formGroup select">
        <div className="bootstrapForm_label">
          <div className="bootstrapForm_radio sizeSelect_radio">
            <FormControl>
              <RadioGroup
                name={itemName}
                onChange={handleChange}
                value={selectedItem}
              >
                {filteredItemList.map((item) => (
                  <div className="sizeSelect_input" key={item.value}>
                    <div className="sizeSelect_img">
                      {item.image_path !== null && <img alt={item.value} src={item.image_path} />}
                    </div>
                    <FormControlLabel
                      control={<Radio />}
                      label={item.price}
                      value={item.value}
                    />
                  </div>

                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RadioSizeSelector

RadioSizeSelector.propTypes = {
  itemList: PropTypes.array,
  itemName: PropTypes.string,
  updateValue: PropTypes.func,
}
