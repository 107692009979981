import { TcFooter } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'
import React from 'react'

const TCFooterWrapper = props => <TcFooter footerItems={props.footerItems} />

TCFooterWrapper.propTypes = {
  footerItems: PropTypes.object
}

export default TCFooterWrapper
