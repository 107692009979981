import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from '../../hooks/useOutsideClickEvent'

const CollectionFilter = ({ collectionFilters, filterValue, setFilterTitle, setFilterValue, setIsParamsRemoved }) => {
  const [isToggled, setIsToggled] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setIsToggled)
  const collapsedPanelClass = isToggled ? 'collectionFilter_options collapse show' : 'collectionFilter_options collapse'
  const collectionFilterToggle = isToggled ? 'collectionFilterToggle toggle-open' : 'collectionFilterToggle'

  let filterTitle = {}

  filterTitle = collectionFilters?.filters.find((item) => {
    if (item?.filters && item?.filters.length > 0) {
      return item?.filters.find((secondLevelItem) => secondLevelItem.filters.value === filterValue)
    }
    return item.value.toString() === filterValue
  })

  useEffect(() => {
    if (filterTitle?.title) setFilterTitle(filterTitle?.title)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTitle?.title])

  return (
    <div className="collectionFilterItem" ref={wrapperRef}>
      <div className="position-relative" id="taxonomies">
        <div className={collectionFilterToggle} onClick={() => setIsToggled(!isToggled)}>
          <span className="label-wrapper">{collectionFilters?.title ? collectionFilters?.title : 'Loading...'}</span>
        </div>
        <div className={collapsedPanelClass}>
          <ul className="cost-list">
            {collectionFilters?.filters.map((item) => {
              return (
                <li className="cost-item" key={Math.random()}>
                  <span
                    className="filterCosts"
                    onClick={() => {
                      setFilterTitle(item.title)
                      setFilterValue(item.value)
                      setIsParamsRemoved(false)
                      setIsToggled(false)
                    }}
                  >
                    {item.title}
                  </span>
                  {item?.filters &&
                    item?.filters.length > 0 &&
                    item?.filters.map((secondLevelItem) => {
                      return (
                        <ul className="sub-category-menu" key={Math.random()}>
                          <li className="cost-item">
                            <span
                              className="filterCosts"
                              onClick={() => {
                                setFilterTitle(secondLevelItem.title)
                                setFilterValue(secondLevelItem.value)
                                setIsParamsRemoved(false)
                                setIsToggled(false)
                              }}
                            >
                              {secondLevelItem.title}
                            </span>
                          </li>
                        </ul>
                      )
                    })}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CollectionFilter

CollectionFilter.propTypes = {
  collectionFilters: PropTypes.object,
  filterValue: PropTypes.any,
  setFilterTitle: PropTypes.func,
  setFilterValue: PropTypes.func,
  setIsParamsRemoved: PropTypes.func,
}
