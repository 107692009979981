import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import TCHeaderSearch from './TCHeaderSearch'

const TCHeaderImageWrapper = props => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }

  const { carousels, fallback, placeholder, search, hideSearch, taxon } = props.data

  const ButtonGroup = ({ next, previous }) => {
    if (carousels.length > 1) {
      return (
        <div className="carousel-button-group">
          <button className="react-multiple-carousel__arrow--left carousel-button" onClick={() => previous()}></button>
          <span className="vi"></span>
          <button className="react-multiple-carousel__arrow--right carousel-button" onClick={() => next()}></button>
        </div>
      )}

    return null
  }

  return (
    <div className="header-container">
      <>
        {carousels.length === 0 ?
          <>
            <div className={`${taxon? 'category-' : ''}header-image`} style={{ backgroundImage: `url(${fallback.img})` }} />
            <div className="container mb-0 p-md-0">
              <div className={`${taxon? 'category-' : ''}header-text`}>
                <h3 id={`${taxon? 'category-' : ''}header-title`}>{fallback.title}</h3>
                <p id={`${taxon? 'category-' : ''}header-subtitle`}>{fallback.subtitle}</p>
              </div>
            </div>
          </>
          :
          <div className="header-carousel">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay={carousels.length > 1}
              autoPlaySpeed={10000}
              centerMode={false}
              className="slider"
              customButtonGroup={<ButtonGroup />}
              draggable={false}
              focusOnSelect={false}
              infinite
              keyBoardControl
              renderButtonGroupOutside={true}
              responsive={responsive}
              showDots={true}
            >
              {carousels.map((item) =>
                <div key={btoa(item.url)}>
                  <div className="header-image" style={{backgroundImage: `url('${item.img}')`}}>
                    <div className="header-carousel-box">
                      <div className="header-carousel-text">
                        <h3 className="header-carousel-title">{item.header}</h3>
                        <h3 className="header-carousel-description">{item.subheader}</h3>
                        <button className="header-carousel-button buttonComponent--primary" onClick={() => window.location.href = item.url}>{item.link_text}</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
          </div>
        }
        {!hideSearch &&
          <TCHeaderSearch placeholder={placeholder} searchBtn={search} />
        }
      </>
    </div>
  )
}

TCHeaderImageWrapper.propTypes = {
  data: PropTypes.object,
  next: PropTypes.func,
  previous: PropTypes.func,
}

export default TCHeaderImageWrapper
