import React from 'react'
import PropTypes from 'prop-types'
import ItemPropertySelector from './ItemPropertySelector'
import NumberInput from './NumberInput'

const BasePaymentTab = ({ data }) => {
  return (
    <div>
      <div className="item-property-selector-list">
        <ItemPropertySelector
          itemLabel={Spree.translation('spree.payment_selector.labels.size')}
          itemList={data.paymentData.variants}
          itemName="size"
        />
        <NumberInput
          itemLabel={Spree.translation('spree.payment_selector.labels.quantity')}
          itemName="quantity"
          itemValue={1}
        />
      </div>
    </div>
  )
}

export default BasePaymentTab

BasePaymentTab.propTypes = {
  data: PropTypes.object,
}
