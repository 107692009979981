import React from 'react'
import { Modal, Backdrop } from '@mui/material'
import PropTypes from 'prop-types'

const ShopModal = ({ isModalOpen, handleClose, modalBody = null, modalTitle = null, classes = '' }) => {
  return (
    <Modal
      closeAfterTransition
      components={{ Backdrop: Backdrop }}
      componentsProps={{
        backdrop: { timeout: 500 },
      }}
      onClose={handleClose}
      open={isModalOpen}
    >
      <div className={`modal-body-wrapper ${classes}`}>
        <div className="closeModalButton" onClick={handleClose}>
          <i className="icon-times" />
        </div>
        <div className="modal-content-wrapper">
          {modalTitle && <h3 className="text-center">{modalTitle}</h3>}
          {modalBody}
        </div>
      </div>
    </Modal>
  )
}

ShopModal.propTypes = {
  classes: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalBody: PropTypes.element,
  modalTitle: PropTypes.string,
}

export default ShopModal
