import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../Breadcrumbs'
import { Loader } from '@tc-packages/tc-common'
import ActiveFilters from './ActiveFilters'
import CollectionCard from './CollectionCard'
import CollectionFilter from './CollectionFilter'
import LoadMore from './LoadMore'
import {
  deleteQueryParams,
  getQueryParams,
  setQueryParams,
} from './QueryParamsActions'
import Search from './Search'
import HeroImage from './HeroImage'

const ListingPage = ({ quickViewModalData }) => {
  const [productsError, setProductsError] = useState(null)
  const [filtersError, setFiltersError] = useState(null)
  const [products, setProducts] = useState(null)
  const [filters, setFilters] = useState(null)
  const [taxonValue, setTaxonValue] = useState('')
  const [priceValue, setPriceValue] = useState('')
  const [taxonTitle, setTaxonTitle] = useState('')
  const [priceTitle, setPriceTitle] = useState('')
  const [keyword, setKeyword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isTaxonParamsRemoved, setIsTaxonParamsRemoved] = useState(false)
  const [isPriceParamsRemoved, setIsPriceParamsRemoved] = useState(false)
  const [isKeywordParamsRemoved, setIsKeywordParamsRemoved] = useState(false)

  const activeFilters = [
    { name: taxonTitle, type: 'taxon' },
    { name: priceTitle, type: 'price' },
    { name: keyword, type: 'keyword' },
  ]

  const getFilters = () => {
    fetch(Spree.routes.api_v2_storefront_filters)
      .then((data) => data.json())
      .then((res) => setFilters(res))
      .catch((error) => setFiltersError(error))
  }

  const getProducts = ({
    taxon = taxonValue,
    price = priceValue,
    keyword: paramsKeyword = keyword
  }) => {
    fetch(
      `${Spree.routes.api_v2_storefront_products_list}?taxon=${taxon}&price=${price}&keywords=${paramsKeyword}`,
    )
      .then(setIsLoading(true))
      .then((data) => data.json())
      .then((res) => {
        sendAnalytics(res, paramsKeyword, price)
        setProducts(res)
      })
      .then(() => {
        setIsKeywordParamsRemoved(false)
      })
      .catch((er) => setProductsError(er))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loadMoreProducts = (page) => {
    fetch(
      `${Spree.routes.api_v2_storefront_products_list}?taxon=${taxonValue}&price=${priceValue}&keywords=${keyword}&page=${page}`,
    )
      .then(setIsLoading(true))
      .then((data) => data.json())
      .then((res) => {
        setProducts((prevState) => {
          return {
            breadcrumbs: res.breadcrumbs,
            pagination: res.pagination,
            records: [...prevState.records, ...res.records],
            taxon_hero: res.taxon_hero,
          }
        })
      })
      .catch((er) => setProductsError(er))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sendAnalytics = (res, searchTerm = null, price = null) => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({
      event: 'search_results',
      data: {
        category: res.taxon_hero?.name || null,
        cost: price,
        resultsCount: res.pagination.total_count,
        searchTerm,
      }
    })
  }

  useEffect(() => {
    deleteQueryParams(
      isKeywordParamsRemoved,
      isTaxonParamsRemoved,
      isPriceParamsRemoved,
    )
  }, [isKeywordParamsRemoved, isPriceParamsRemoved, isTaxonParamsRemoved])

  useEffect(() => {
    setQueryParams(taxonValue, priceValue, keyword)

    const params = getQueryParams()
    params.taxon && setTaxonValue(params.taxon)
    params.price && setPriceValue(params.price)
    params.keyword && setKeyword(params.keyword)
    getProducts(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, priceValue, taxonValue])

  useEffect(() => {
    getFilters()
  }, [])

  return (
    <>
      {productsError || filtersError ? (
        <div>
          Something went wrong, please try to reload page one more time.
        </div>
      ) : (
        <div>
          {products?.taxon_hero ? (
            <HeroImage taxonHero={products?.taxon_hero} />
          ) : null}
          <section className="pt-0">
            <div className="container">
              <Breadcrumbs breadcrumbs={products?.breadcrumbs} />
              <div className="searchFiltersWrapper">
                <div className="filterWrapper">
                  <CollectionFilter
                    collectionFilters={filters ? filters[0] : null}
                    filterValue={taxonValue}
                    setFilterTitle={setTaxonTitle}
                    setFilterValue={setTaxonValue}
                    setIsParamsRemoved={setIsTaxonParamsRemoved}
                  />
                  <CollectionFilter
                    collectionFilters={filters ? filters[1] : null}
                    filterValue={priceValue}
                    setFilterTitle={setPriceTitle}
                    setFilterValue={setPriceValue}
                    setIsParamsRemoved={setIsPriceParamsRemoved}
                  />
                </div>
                <Search
                  getProducts={getProducts}
                  isLoading={isLoading}
                  keyword={keyword}
                  setKeyword={setKeyword}
                />
              </div>
              <ActiveFilters
                activeFilters={activeFilters}
                setIsKeywordParamsRemoved={setIsKeywordParamsRemoved}
                setIsPriceParamsRemoved={setIsPriceParamsRemoved}
                setIsTaxonParamsRemoved={setIsTaxonParamsRemoved}
                setKeyword={setKeyword}
                setPriceTitle={setPriceTitle}
                setPriceValue={setPriceValue}
                setTaxonTitle={setTaxonTitle}
                setTaxonValue={setTaxonValue}
              />
              <div className="collectionCardWrapper">
                {products?.records && isLoading ? (
                  <div
                    className="loaderWrapper"
                    style={{ position: 'relative', width: '100%', zIndex: '3' }}
                  >
                    <Loader isLarge />
                  </div>
                ) : null}
                <div className="collectionCard_list row">
                  {products?.records ? (
                    products?.records?.length > 0 ? (
                      products?.records.map((item) => {
                        return (
                          <CollectionCard
                            advertise={item.advertise}
                            id={item.id}
                            image={item.image}
                            isLoading={isLoading}
                            key={Math.random()}
                            more_than_one_variant={item.more_than_one_variant}
                            name={item.name}
                            oldPrice={item.old_price}
                            onSale={item.on_sale}
                            price={item.price}
                            quickViewModalData={quickViewModalData}
                            topLabel={item.top_label}
                            url={item.url}
                          />
                        )
                      })
                    ) : (
                      <div style={{ margin: '2rem 0rem' }}>
                        {Spree.translation('no_products_found')}
                      </div>
                    )
                  ) : (
                    <div className="loaderWrapper" style={{ width: '75px' }}>
                      <Loader isLarge />
                    </div>
                  )}
                </div>
              </div>
              {products?.pagination.total_count ? (
                <LoadMore
                  displayedItemsNumber={products?.records?.length}
                  isLoading={isLoading}
                  loadMoreProducts={loadMoreProducts}
                  pagination={products?.pagination}
                />
              ) : null}
            </div>
          </section>
        </div>
      )}
    </>
  )
}

ListingPage.propTypes = {
  quickViewModalData: PropTypes.object,
}

export default ListingPage
