import { createTheme } from '@mui/material/styles'

const tcTheme = createTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      main: '#1c8f61',
    },
    secondary: {
      contrastText: '#fff',
      main: '#414141',
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'Gotham Book',
      ].join(','),
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },

  },
  shadows: Array(25).fill('none'),
})
export default tcTheme
