import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material'
import { TcNavbar } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'
import tcTheme from './TCTheme'

const TCNavbarWrapper = ({ apiSearch, cartPath, cartConfig, locales, logo, logoUrl, profileData, t, data }) => {
  const [state, setState] = useState({
    logo: '',
    menuItems: [],
  })

  useEffect(() => {
    fetch(data)
      .then(res => res.json())
      .then(result => {
        setState(result)
      })
  }, [data])

  const { menuItems } = state

  return (
    <ThemeProvider theme={tcTheme}>
      <TcNavbar
        activeLocaleCode={locales.activeCode}
        apiSearch={apiSearch}
        cartConfig={cartConfig}
        cartPath={cartPath}
        isShop={true}
        localeFlags={locales.flags}
        locales={locales.list}
        logo={logo}
        logoUrl={logoUrl}
        menuItems={menuItems}
        profileData={profileData}
        t={t}
      />
    </ThemeProvider>
  )
}

TCNavbarWrapper.propTypes = {
  apiSearch: PropTypes.object,
  cartConfig: PropTypes.object,
  cartPath: PropTypes.string,
  data: PropTypes.string,
  locales: PropTypes.object,
  logo: PropTypes.string,
  logoUrl: PropTypes.string,
  pathOrigin: PropTypes.string,
  profileData: PropTypes.object,
  t: PropTypes.any,
}

export default TCNavbarWrapper
