import React, { useState } from 'react'
import PropTypes from 'prop-types'

const TCHeaderSearch = ({ querypath = '/products?utf8=✓&taxon=&keywords=', keywords, placeholder }) => {
  const [SearchText, setSearchText] = useState('')
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      window.location.href = `${window.location.origin}${querypath}${SearchText}`
    } else {
      setSearchText(event.target.value)
    }
  }

  return (
    <div className="form-group pb-0">
      <input className="form-control" defaultValue={keywords} onKeyUp={handleKeyPress} placeholder={placeholder} type="text" />
    </div>
  )
}

TCHeaderSearch.propTypes = {
  keywords: PropTypes.string,
  placeholder: PropTypes.string,
  querypath: PropTypes.string,

}

export default TCHeaderSearch
