import React from 'react'
import PropTypes from 'prop-types'

const NumberInput = ({ itemValue, updateValue }) => {
  const decrease = () => {
    updateValue(ensureRange(Number(itemValue)-1))
  }

  const increase = () => {
    updateValue(ensureRange(Number(itemValue)+1))
  }

  const handleChange = (e) => {
    updateValue(ensureRange(Number(e.target.value.replace(/^0+/, ''))))
  }

  const ensureRange = (value) => {
    if (!value || value === 0) { return 1 }
    if (value > 10000) { return 10000 }
    return value
  }

  return (
    <div className="productRightSide_quantitySelect">
      <button
        className="productRightSide_quantityButton less shopping-cart-item__quantity-decrease"
        name="decrease"
        onClick={decrease}
        type="button"
      >
        -
      </button>
      <input
        aria-label="quantity"
        className="productRightSide_quantityInput"
        id="quantityInput"
        max="10000"
        min="1"
        onChange={e => handleChange(e)}
        type="number"
        value={itemValue}
      >
      </input>
      <button
        className="productRightSide_quantityButton more"
        name="increase"
        onClick={increase}
        type="button"
      >
        +
      </button>
    </div>
  )
}

export default NumberInput

NumberInput.propTypes = {
  itemValue: PropTypes.number,
  updateValue: PropTypes.func,

}
