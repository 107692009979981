import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

const ProgressBar = ({ displayedItemsNumber, pagination }) => {
  const [width, setWidth] = useState(0)

  useMemo(() => {
    const calculatedValue = (displayedItemsNumber / pagination?.total_count) * 100

    setWidth(calculatedValue)
  }, [displayedItemsNumber, pagination?.total_count])

  const progressWidth = {
    width: `${width}%`,
  }

  return (
    <div className="progressWrapper">
      <div className="progressLine" style={displayedItemsNumber && progressWidth} />
    </div>
  )
}

export default ProgressBar

ProgressBar.propTypes = {
  displayedItemsNumber: PropTypes.number,
  pagination: PropTypes.object,
}
