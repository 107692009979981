import React from 'react'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'

const defaultOptions = {
  allowedAttributes: {
    a: ['href'],
    img: ['src', 'alt'],
  },
  allowedTags: [
    'b', 'i', 'em', 'strong', 'a',
    'div', 'span', 'img', 'table',
    'tr', 'td', 'ol', 'ul', 'li'],
}

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, options = { ...defaultOptions, ...options }),
})

export const TCSanitizeHTML = ({ html, options, className }) => {
  const divClassName = `${className}`

  return <div className={divClassName} dangerouslySetInnerHTML={sanitize(html, options)} />
}

TCSanitizeHTML.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string,
  options: PropTypes.object,
}

export default TCSanitizeHTML
