import React from 'react'
import PropTypes from 'prop-types'

// TitleSection: A component that displays a centered title.
export default function TitleSection({ section }) {
  const { title, node_id } = section

  return (
    <section key={node_id}>
      <div className="sectionTitle--centered">
        <div className="sectionTitle--centered_container container">
          <div className="sectionTitle--centered_contentWrapper contentWrapper_small">
            <h3 className="sectionTitle--centered_title">
              {title}
            </h3>
          </div>
        </div>
      </div>
    </section>
  )
}

TitleSection.propTypes = {
  section: PropTypes.shape({
    node_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
