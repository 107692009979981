import React from 'react'
import PropTypes from 'prop-types'

// HeroFooterSection: A component that displays a hero section with a background color in the footer.
export default function HeroFooterSection({ section }) {
  const {
    background_color,
    button_color,
    button_background,
    button_color_hover,
    button_background_hover,
    link_open_in_new_tab,
    button_arrow,
    title_color,
  } = section

  const sectionStyles = {
    backgroundColor: background_color,
  }

  const buttonStyles = {
    '--button-background': button_background,
    '--button-background-hover': button_background_hover,
    '--button-color': button_color,
    '--button-color-hover': button_color_hover,
  }

  const headingStyles = {
    color: title_color, // Set the color for the heading based on title_color
  }

  return (
    <div className="imageOverlay imageOverlayFooter" key={section.node_id} style={sectionStyles}>
      <div className="imageOverlay_container container">
        <div className="imageOverlay_row">
          <h4 className="imageOverlay_heading" style={headingStyles}>
            {section.title}
          </h4>
          {section.button_url.trim() !== '' && section.button_label.trim() !== '' && (
            <a
              aria-label="Learn more"
              className={`landingLinkBtn ${button_arrow}`}
              href={section.button_url}
              rel={link_open_in_new_tab ? 'noreferrer noopener' : ''}
              style={buttonStyles}
              target={link_open_in_new_tab ? '_blank' : '_self'}
            >
              {section.button_label}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

HeroFooterSection.propTypes = {
  section: PropTypes.shape({
    background_color: PropTypes.string.isRequired,
    button_arrow: PropTypes.string.isRequired,
    button_background: PropTypes.string.isRequired,
    button_background_hover: PropTypes.string.isRequired,
    button_color: PropTypes.string.isRequired,
    button_color_hover: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    link_open_in_new_tab: PropTypes.bool.isRequired,
    node_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    title_color: PropTypes.string.isRequired,
  }).isRequired,
}
