import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Pagination } from '@mui/material'
import TCPaginationReviewItem from './TCPaginationReviewItem'

const TCPagination = ({ productReviewCount, productReviews, setHeight, translations }) => {
  const [page, setPage] = useState(1)
  const pageLimit = 5
  const totalPages = useMemo(() => Math.ceil(productReviewCount / pageLimit), [productReviewCount, pageLimit])

  const handleChange = useCallback((event, value) => {
    document.getElementById('reviewsTop')?.scrollIntoView()
    setPage(value)
    setHeight()
  }, [setHeight])

  const generateReviews = useMemo(() => {
    const offset = (page - 1) * pageLimit
    return productReviews.slice(offset, offset + pageLimit).map(item => (
      <TCPaginationReviewItem item={item} key={item.id} translations={translations} />
    ))
  }, [page, pageLimit, productReviews])

  return (
    <div className="review-pagination">
      {generateReviews}
      <Pagination count={totalPages} defaultPage={1} onChange={handleChange} page={page} />
    </div>
  )
}

TCPagination.propTypes = {
  productReviewCount: PropTypes.number.isRequired,
  productReviews: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image_large: PropTypes.string,
      image_thumb: PropTypes.string,
      name: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      review: PropTypes.string.isRequired,
      show_identifier: PropTypes.bool.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
  setHeight: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    average: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    empty: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
    readMore: PropTypes.string.isRequired,
    readLess: PropTypes.string.isRequired,
    reviews: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
  }).isRequired,
}

export default TCPagination
