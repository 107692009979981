import React from 'react'

export const renderStars = (rating) => {
  const totalStars = 5

  if (rating === null) return null

  return (
    <>
      {Array.from({ length: totalStars }, (_, i) => (
        <span className={i < rating ? 'full' : 'empty'} key={i}>☆</span>
      ))}
    </>
  )
}

export const renderStarsPercentage = (itemRate) => {
  const totalStars = 5
  const starPercentageRounded = Math.round((itemRate / totalStars) * 100)

  return (
    <div
      className="stars-inner"
      key={btoa(itemRate.toString())}
      style={{ width: `${starPercentageRounded}%` }}
    />
  )
}
