import React from 'react'
import PropTypes from 'prop-types'
import { TcAddToCartSuccessModal } from '@tc-packages/tc-common'

const TCAddToCartSuccessWrapper = ({ config }) => (
  <TcAddToCartSuccessModal config={config} />
)

TCAddToCartSuccessWrapper.propTypes = {
  config: PropTypes.object.isRequired,
}

export default TCAddToCartSuccessWrapper
