import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TCSanitizeHTML from './TCSanitizeHTML'

const TCProductDescription = props => {
  const [isOpened, setToOpened] = useState(false)

  const toggleCollapse = (e) => {
    const content = e.target.nextElementSibling
    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = `${content.scrollHeight}px`
    }
    setToOpened(!isOpened)
  }

  return (
    <div>
      <h2 className="product-details-title" onClick={(e) => toggleCollapse(e)}>
        {isOpened ? <span className="list-prefix">-</span> : <span className="list-prefix">+</span>} {props.productDetails.title}
      </h2>
      <div className={isOpened ? 'product-details-collapsible active' : 'product-details-collapsible'}>
        <TCSanitizeHTML html={props.productDetails.description} />
      </div>
      <hr className="line" />
    </div>
  )
}

TCProductDescription.propTypes = {
  open: PropTypes.bool,
  productDetails: PropTypes.object,

}

export default TCProductDescription
