import React from 'react'
import PropTypes from 'prop-types'

const HeroImage = ({taxonHero}) => {
  return (
    <div className="imageOverlay" style={{backgroundImage: `url('${taxonHero?.icon_url}')`}}>
      <div className="imageOverlay_container container">
        <div className="imageOverlay_row contentWrapper_large">

          <div className="imageOverlay_content m-0">
            <h1 className="imageOverlay_heading">{taxonHero.name}</h1>
            <div className="imageOverlay_text">
              <p>{taxonHero.description}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default HeroImage

HeroImage.propTypes = {
  taxonHero: PropTypes.object,
}
