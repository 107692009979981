import React from 'react'
import PropTypes from 'prop-types'

const TCTileRichList = ({ rich_list_items, translations }) => (
  <div className="richlist-wrapper">
    <h1 className="main-title richlist-title">{translations.title}</h1>
    <p className="richlist-description">{translations.description}</p>
    <div className="richlist-group-wrapper">
      {rich_list_items.map(item =>
        <div className="richlist-product-wrapper" key={btoa(item.title)}>
          <a href={item.btn_link}>
            <img alt="" className="richlist-img" src={item.image} />
          </a>
          <a href={item.btn_link}>
            <h4 className="richlist-product-title">{item.title}</h4>
          </a>
          <div className="richlist-product-text">
            <p className="richlist-product-description">
              {item.description}
            </p>
          </div>
        </div>
      )}
    </div>
  </div>
)

TCTileRichList.propTypes = {
  rich_list_items: PropTypes.arrayOf(
    PropTypes.shape({
      btn_label: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      rich_list_items: PropTypes.array,
      title: PropTypes.string,
    })
  ),
  translations: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default TCTileRichList
