import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, Select } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'

const ItemPropertySelector = ({
  itemList = [],
  itemName,
  updateValue,
  variant,
  sizeClass,
}) => {
  const [isOpen, isDropdownOpen] = React.useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDocument)

    return () => window.removeEventListener('scroll', handleScrollDocument)
  }, [])
  function handleScrollDocument() {
    isDropdownOpen(false)
  }

  // Context API
  const filteredItemList = itemList.filter((item) => !!item.name)

  // variables
  const display = filteredItemList.length > 0
  const selectedItem = variant

  const handleChange = (e) => {
    updateValue(e.target.value)
  }

  return (
    display && (
      <div className={`${itemName} ${sizeClass}`}>
        <div className="bootstrapForm_formGroup select">
          <div className="bootstrapForm_label">
            <div className="bootstrapForm_input product-select_wrapper">
              <FormControl>
                <Select
                  disableunderline="true"
                  IconComponent={ArrowDropDown}
                  inputProps={{
                    id: 'item-simple',
                    name: 'value',
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      horizontal: 'left',
                      vertical: 'bottom',
                    },
                    classes: { paper: 'subscriptionSelectItems' },
                    disableScrollLock: true,
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'left',
                      vertical: 'top',
                    },
                  }}
                  onChange={handleChange}
                  onClose={() => isDropdownOpen(false)}
                  onOpen={() => isDropdownOpen(true)}
                  open={isOpen}
                  value={selectedItem}
                >
                  {filteredItemList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default ItemPropertySelector

ItemPropertySelector.propTypes = {
  itemList: PropTypes.array,
  itemName: PropTypes.string,
  sizeClass: PropTypes.string,
  updateValue: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
