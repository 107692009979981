import React from 'react'
import PropTypes from 'prop-types'

// HeroSection: A component that displays a hero section with a background image, logo, and a call-to-action button.
export default function HeroSection({ section }) {
  const {
    node_id,
    image_url,
    logo_alt,
    logo_url,
    title,
    button_url,
    button_label,
    button_color,
    button_background,
    button_color_hover,
    button_background_hover,
    link_open_in_new_tab,
    button_arrow,
  } = section

  const buttonStyles = {
    '--button-background': button_background,
    '--button-background-hover': button_background_hover,
    '--button-color': button_color,
    '--button-color-hover': button_color_hover,
  }

  return (
    <div className="imageOverlay" key={node_id} style={{ backgroundImage: `url(${image_url})` }}>
      <div className="imageOverlay_container container">
        <div className="contentWrapper_large">
          <div className="imageOverlay_row">
            <div className="imageOverlay_content m-0 px-0">
              <img alt={logo_alt} className="imageOverlay_logo" src={logo_url} />
              <h2 className="imageOverlay_heading">
                <strong>
                  {title}
                </strong>
              </h2>
              {section.button_url.trim() !== '' && section.button_label.trim() !== '' && (
                <a aria-label="Learn more"
                  className={`landingLinkBtn ${button_arrow}`}
                  href={button_url}
                  rel={link_open_in_new_tab ? 'noreferrer noopener' : ''}
                  style={buttonStyles}
                  target={link_open_in_new_tab ? '_blank' : '_self'}
                >
                  {button_label}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HeroSection.propTypes = {
  section: PropTypes.shape({
    button_arrow: PropTypes.string.isRequired,
    button_background: PropTypes.string.isRequired,
    button_background_hover: PropTypes.string.isRequired,
    button_color: PropTypes.string.isRequired,
    button_color_hover: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    link_open_in_new_tab: PropTypes.bool.isRequired,
    logo_alt: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    node_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
