export const setQueryParams = (taxon, price, keyword) => {
  let url = new URL(window.location)

  if (taxon) url.searchParams.set('taxon', taxon)
  if (price) url.searchParams.set('price', price)
  if (keyword) url.searchParams.set('keywords', keyword)

  if (taxon || price || keyword) window.history.pushState({}, '', url)
}

export const deleteQueryParams = (isKeywordParamsRemoved, isTaxonParamsRemoved, isPriceParamsRemoved) => {
  let url = new URL(window.location)

  if (isTaxonParamsRemoved) url.searchParams.delete('taxon')
  if (isPriceParamsRemoved) url.searchParams.delete('price')
  if (isKeywordParamsRemoved) url.searchParams.delete('keywords')

  if (isKeywordParamsRemoved || isTaxonParamsRemoved || isPriceParamsRemoved) window.history.pushState({}, '', url)
}

export const getQueryParams = () => {
  const url = new URL(window.location)

  const keyword = url.searchParams.get('keywords') || ''
  const price = url.searchParams.get('price') || ''
  const taxon = url.searchParams.get('taxon') || ''

  return { keyword, price, taxon }
}
