import PropTypes from 'prop-types'
import React from 'react'

const Breadcrumbs = ({ breadcrumbs }) => {
  const lastIndex = breadcrumbs?.length - 1

  return (
    <nav id="breadcrumbs">
      <ol className="breadcrumb">
        {breadcrumbs?.map((item, index) => {
          if (index === lastIndex) {
            return (
              <li className="breadcrumb-item" key={Math.random()}>
                <span>{item.label}</span>
              </li>
            )
          } else {
            return (
              <li className="breadcrumb-item" key={Math.random()}>
                <a href={item.url}>
                  <span>{item.label}</span>
                </a>
              </li>
            )
          }
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
}
