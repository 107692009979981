import React from 'react'
import PropTypes from 'prop-types'

const PriceLabel = ({ price, on_sale, old_price, self_served = false, subsidy }) => {
  const productPriceClassName = `productRightSide_price mb-3 d-flex align-items-baseline ${on_sale ? 'onSale' : ''}`

  return (
    <div className={productPriceClassName}>
      <span className="productRightSide_priceNormal">{price}</span>
      {on_sale && (
        <>
          <span className="productRightSide_priceSale me-4 onSale">{old_price}</span>
          {self_served && (
            <span className="onSale fw-medium grey-text">
              (<span className="productRightSide_subsidy">{subsidy} </span> {Spree.translation('spree.brand_subsidy')})
            </span>
          )}
        </>
      )}
      {!on_sale && price !== old_price && (
        <span className="productRightSide_priceSale me-4 onSale">{old_price}</span>
      )}
    </div>
  )
}

export default PriceLabel

PriceLabel.propTypes = {
  old_price: PropTypes.string,
  on_sale: PropTypes.bool,
  price: PropTypes.string,
  self_served: PropTypes.bool,
  subsidy: PropTypes.string,
  t: PropTypes.object,
}
